<template>
  <div class="page-content w-100">
    <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Home</p>
      </div>
    </div>
    <div class="messageBox">
      <div class="row">


        <div v-for="order in getFootwearOrder" :key="order.id">
          <div v-if="order.order_status == 'Accepted'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Your order is accepted
                  </h4>
                </div>
                <div class="col-12 mt-3">
                  Your order for custom footwear for {{ this.correspondingFootwearOrder[0].firstName }} {{
                    this.correspondingFootwearOrder[0].lastName }} is accepted. The order number is {{ 10000 +
                    Number(order.id) }} and the planned delivery date is {{formatDate(order.deliveryDate)}}
                </div>
                <div class="col-12 mt-3">
                  We will let you know once the order is shipped.
                </div>
              </div>


              <div class="row">
                <div class="col-9 mt-3">
                  Best wishes
                  <p>{{ order.admin_customer_id.users.firstName }}</p>
                </div>

                <div class="col-3 mt-3">
                  {{ this.correspondingFootwearOrder[0].updateAt }}
                </div>

              </div>




            </div>
          </div> 

          <div v-if="order.order_status == 'Accepted'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">You have a new order!
                  </h4>
                </div>
                <div class="col-12 mt-3">
                  New footwear order received from {{ this.correspondingFootwearOrder[0].firstName }} {{
                    this.correspondingFootwearOrder[0].lastName }} through
                </div>
                <div class="col-12 mt-3">
                  Order No.:{{ 10000 + Number(order.id) }}
                </div>
              </div>

              <div class="row">
                <div class="col-9 mt-3">
                  Best wishes
                  <br>
                  RxIx Admin
                </div>

                <div class="col-3 mt-3">
                  {{ this.correspondingFootwearOrder[0].updateAt }}
                </div>

              </div>





            </div>
          </div> 




          <div v-if="order.order_status == 'Confirmed'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Thanks for confirming your selection
                  </h4>
                </div>
                <div class="col-12 mt-3">
                  We record your confirmation of the footwear model, {{ order.confirmed_model }}.
                </div>
                <div class="col-12 mt-3">
                  You will receive further updates about the order on this page.
                </div>
              </div>

            </div>
          </div>
        </div>



        <!-- <div class="row d-none d-lg-block">
          <div class="col-md-12 col-12 wistomBorder"  @click="getUsersStatus()" >
            <div class="border-color p-4" style="border-radius: 20px; background-color: #ffff">
              Publish my wisdom
            </div>
          </div>
         
        </div> -->
        <!-- <div class="d-block d-lg-none">
          <div class="btn-floating-add">
            <router-link :to="'/' + role + '/newpost'" class="nav-link text-color">
              <font-awesome-icon :icon='["fas", "plus-circle"]' />
            </router-link>
          </div>
        </div> -->

        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <div v-for="status in doctorStatus" :key="status">
          <div class="box-shadow2 cardBorder" v-if="status.doctorstatus === '2'">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  Errors in your profile information
                </h4>
              </div>
              <div class="col-12 mt-3">
                Our review team observed the following in your profile
                information. Please fix the errors in the About Me section of your
                settings page.
              </div>
              <div class="col-12 mt-3">
                <span class="textstyle"> {{ status.comments_history }}</span>
                <p class="timestyle">{{ status.createdAt }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
              </div>
              <div class="col-md-12 col-6">
                <p class="text-end" style="float: right">
                  {{ status.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>

          </div>
          <div class="box-shadow2 cardBorder" v-if="status.doctorstatus === '1'">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  You now have access to select RxIx features
                </h4>
              </div>
              <div class="col-12 mt-3">
                You can now offer consultation using RxIx, access a patient’s records,
                and view the related doctors’ case sheets.
                You can create a patient’s case sheet and upload your prescription.
              </div>
              <div class="col-12 mt-3">
                Creating a case sheet is just like creating a message.
                To upload your prescription,
                take a photo of your handwritten prescription and upload the image.

              </div>
              <div class="col-12 mt-3">
                Update the <a class="anchorTag" @click="consultationPlace()"> <u>Consultations - My places</u></a>
                section and get access to a library of resources.
                We also enable RxIx users to contact you directly.
                You may opt to receive home consultation requests from our users.

              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">Best Wishes!</p>

                <p class="text-start font-weight-bold">RxIx Admin</p>
              </div>
              <div class="col-md-6 col-6 mt-3">
                <p class="text-end" style="float: right">
                  {{ status.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>

        <!-- admin page patient group add -->
        <div v-for="patientGroup in patientfiltercheckValue" :key="patientGroup">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  New patient support group added to RxIx
                </h4>
              </div>
              <div class="row box-items-space1">
                <div class="col-opt-box1">
                  <div class="col-fix mt-3 color-bg1">
                    <div class="btn-text-bg1">
                      <!-- <div class="box-text-bg1">
                        {{ patientGroup.shortname }}
                      </div> -->
                      <div class="eyes-text1">
                        <p>{{ patientGroup.groupname }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <div class="btn btn-action-background1" @click="joinPatientGroup(patientGroup)">
                      Join group
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-12 mt-5">
                Once you join the community, will also be added to your list of
                practice specialties.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">Best Wishes!</p>
              </div>
              <div class="col-md-12 col-6">
                <p class="text-end">{{ patientGroup.createdAt }}</p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>

        <!-- admin page practice area add -->
        <div>
          <div class="box-shadow2 cardBorder" v-for="index in filtercheckValue" :key="index">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  New community for doctors added to RxIx
                </h4>
              </div>
            </div>
            <div class="row box-items">
              <div class="col-opt">
                <div class="col-fix mt-3">
                  <div class="btn-text">
                    {{ index.practiceArea }}
                    <div>Doctors</div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-action">Join community</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12 mt-5">
                Once you join the community,will also be added to your list of
                practice specialties.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">Best Wishes!</p>
              </div>

              <div class="col-md-6 col-6">
                <p class="text-end">{{ index.updateAt }}</p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>

        <!-- Message show if sign up by clinic consultation card  -->
        <div v-if="getCommunity.doctor_card_type === 2">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  Happy to have you on-board
                </h4>
              </div>
              <div class="col-12 mt-3">
                Thanks for showing your interest in using RxIx for your patient
                consultations. Please update your profile settings so that the
                system updates your access to the consultation section.
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end" style="float: right">
                    {{ getCommunity.createdAt }}
                  </p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
        </div>

        <!-- Message show if sign up by Offer consultation  -->
        <div v-if="getCommunity.doctor_card_type === 4">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  Offer consultation with better patient info
                </h4>
              </div>
              <div class="col-12 mt-3">
                Our initiative is to support doctors to get access to inputs
                that help them offer better consultation. Apart from the
                patient’s prescription records, we also endeavour to provide you
                with the doctors’ case sheets as you have seen in the sample.
                RxIx stands to become useful in health care delivery when more
                doctors use it to record their consultations. We take this
                opportunity to request you to invite your fellow doctors to use
                RxIx as that is the only way we can together make a difference
                in healthcare.
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end" style="float: right">
                    {{ getCommunity.createdAt }}
                  </p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
        </div>

        <!-- Message show if sign up by access reliable info  -->
        <div v-if="getCommunity.doctor_card_type === 5">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">
                  Happy to have you on-board
                </h4>
              </div>
              <div class="col-12 mt-3">
                RxIx offers a true practice management app. Access to clinical
                resources is one aspect of our offering to doctors. All the
                currently available resources are free-to-use for life. Please
                update your profile settings to access them.
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end" style="float: right">
                    {{ getCommunity.createdAt }}
                  </p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
        </div>

        <!-- admin approved message -->
        <!-- <div
          v-if="getCommunity.updated_by == 'admin-customer'">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Invitation to manage {{ getCommunity?.customer?.companyName }} on
                  RxIx</h4>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 mt-3">
                Greetings from RxIx!
              </div>
              <div class="col-12 mt-3">
                You have been added to {{ getCommunity?.customer?.companyName }} on RxIx.
                You have also been provided with the privilege to administrate the account on the app.
                Please sign in to the app using <a href="https://rxix.in/signup">https://rxix.in/signup</a> .
              </div>
              <div class="col-12 mt-3">
                For any queries and support, feel free to connect with the RxIx WhatsApp Help Desk.
              </div>
              <div class="row mt-4">
                <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">Best Wishes!</p>
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                  </div>
                <div class="col-md-6 col-6 mt-3">
                  <p class="text-end">{{ getCommunity.createdAt }}</p>
                </div>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div> -->
        <!-- <div v-if="getCommunity.updated_by == 'admin-customer' && getCommunity.isActive == false">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Access to manage {{ getCommunity?.customer?.companyName }} on RxIx
                  is removed</h4>
              </div>
              <div class="col-12 mt-3">
                On the directions of {{ getCommunity?.customer?.companyName }},your privilege to administrate the
                account
                on RxIx is removed.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">You now have access to regular features applicable to your user
                  type.</p>
              </div>
              <div class="col-12 mt-3">
                For any queries, feel free to connect with the RxIx WhatsApp Help Desk.</div>
              <div class="col-md-12 col-6">
                <p class="text-end">{{ getCommunity.createdAt }}</p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div> -->
        <div v-for="doctorGroup in allUniqueDoctorHistoryData" :key="doctorGroup">
          <div v-if="doctorGroup.patientGroupStatus === 'patient_group_created'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Membership - Patients group</h4>
                </div>
                <div class="col-12 mt-3">
                  You are now part of the
                  {{ doctorGroup.doctorGroupName.group_name }} patients group. To interact and engage with the
                  community,
                  please ensure that your profile is complete.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end">{{ doctorGroup.createdAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
          <div v-if="doctorGroup.patientGroupStatus === 'patient_group_deleted'">
            <div class="box-shadow1 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Membership - Patients group</h4>
                </div>
                <div class="col-12 mt-3">
                  Since you have removed {{ doctorGroup.doctorGroupName.groupname }} from your
                  patient groups, you are no more a member of the group.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">

                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end" style="float: right">
                    {{ doctorGroup.createdAt }}
                  </p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>

          <div v-if="doctorGroup.patientGroupStatus === 'practice_area_created'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Membership - Doctors community</h4>
                </div>
                <div class="col-12 mt-3">
                  You are now part of the
                  {{ doctorGroup.doctorPracticeArea.practiceArea }} doctors community. To interact and engage with the
                  community of doctors, please ensure that your profile is complete.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">Best Wishes!</p>

                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-6 col-6 mt-3 ">
                  <p class="text-end">{{ doctorGroup.createdAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>

          <div v-if="doctorGroup.patientGroupStatus === 'practice_area_deleted'">
            <div class="box-shadow1 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Membership - Doctors community</h4>
                </div>
                <div class="col-12 mt-3">
                  Since you have removed {{ doctorGroup.doctorPracticeArea.practiceArea }} from your
                  practice areas, you are no more a member of the related doctors
                  community.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">Best Wishes!</p>
                
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end" style="float: right">
                    {{ doctorGroup.createdAt }}
                  </p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
        </div>
        <div v-if="getCommunity?.clinic_hcp_id && getCommunity?.clinic_admin_id == null">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">You are now an administrator of {{ clinicAdmin[0].clinic_name }} on
                  RxIx</h4>
              </div>
              <div class="col-12 mt-3">
                As a clinic administrator on RxIx, you have the privilege to add team members.
                You may also connect and work with healthcare vendors registered on the app for
                the benefit of your clinic’s operations as also to enhance what you offer to your patients.
              </div>
            </div>
            <div class="row mt-3">
              <p class="text-start font-weight-bold">Best Wishes!</p>

              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
              </div>
              <div class="col-md-6 col-6">
                <p class="text-end" style="float: right">
                  {{ getCommunity.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>
        <div v-if="getCommunity?.clinic_hcp_id && getCommunity?.clinic_admin_id">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">You are now an administrator of {{ clinicAdmin[0].clinic_name }} on
                  RxIx</h4>
              </div>
              <div class="col-12 mt-3">
                As a clinic administrator on RxIx, you have the privilege to add team members.
                You may also connect and work with healthcare vendors registered on the app for
                the benefit of your clinic’s operations as also to enhance what you offer to your patients.
              </div>
            </div>
            <div class="row mt-3">
              <p class="text-start font-weight-bold">Best Wishes!</p>

              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">{{ getCommunity?.clinic_admin_name }}</p>
              </div>
              <div class="col-md-6 col-6">
                <p class="text-end" style="float: right">
                  {{ getCommunity.createdAt }}
                </p>
              </div>
            </div>

            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>
        <div v-if="getCommunity?.clinic_admin_id">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">You are now part of the team at {{ clinicTeamAdmin[0].clinic_name
                  }}</h4>
              </div>
              <div class="col-12 mt-3">
                Welcome to the clinic’s team. Please complete your settings page.
                If you have any queries about the app,
                reach out to the RxIx Help Desk over WhatsApp on 82960 13460.
              </div>
            </div>
            <div class="row mt-3">
              <p class="text-start font-weight-bold">Best Wishes!</p>
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">{{ getCommunity?.clinic_admin_name }}</p>
              </div>
              <div class="col-md-6 col-6">
                <p class="text-end" style="float: right">
                  {{ getCommunity.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>

        <div v-if="getCommunity?.clinic_hcp_id && getCommunity?.clinic_admin_id == null">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Welcome to {{  this.clinicAdmin[0].admin_customer_id.companyShortName}}</h4>
              </div>
              <div class="col-12 mt-3">
                We are happy to add {{ clinicAdmin[0].clinic_name }} as our partner clinic. You can order products from us through the RxIx app. We look forward to a mutually beneficial relationship.
<br>
<p class="mt-4">
To know more, please sign in to the app using https://rxix.in/signup with the mobile number  For any queries, feel free to connect 
              </p></div>
            </div>
            <div class="row mt-3">
              <p class="text-start font-weight-bold">Best Wishes!</p>
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">{{ getCommunity?.clinic_admin_name }}</p>
              </div>
              <div class="col-md-6 col-6">
                <p class="text-end" style="float: right">
                  {{ getCommunity.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>

        <!-- welcome message for all the cards-->
        <div v-if="getCommunity">
          <div class="box-shadow2 cardBorder">
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Welcome to RxIx</h4>
              </div>
              <div class="col-12 mt-3">
                It is our pleasure to welcome you to RxIx.
                Our initiative is to support doctors in their patient care efforts.
              </div>
              <div class="col-12 mt-3">
                We intend to keep most of our services free to single-doctor clinics and charge an affordable fee to
                multi-doctor clinics.
              </div>
              <div class="col-12 mt-3">
                Please take a quick tour of the app to learn about the services we currently offer.
                We have designed RxIx such that some of its features get unlocked for your use once we know more about
                you.
                Please help us open up the features by updating your profile and settings page.
              </div>
              <div class="d-none d-lg-block">
                <div class="col-12 mt-3">
                  Complete the <a class="anchorTag" @click="practiceDesktop()"> <u> About me </u></a> section to join
                  groups and communities.
                  You also get to view a patient’s case sheets across RxIx doctors and record your patient
                  consultations.
                </div>
              </div>
              <div class="d-block d-lg-none">
                <div class="col-12 mt-3">
                  Complete the <a class="anchorTag" @click="practiceMobile()"><u> About me </u></a> section to join
                  groups and communities.
                  You also get to view a patient’s case sheets across RxIx doctors and record your patient
                  consultations.
                </div>
              </div>
              <!-- HERE THE NAVIGATION IS DIFFRENT SO I USE THE BUTTON 2 TIMES  -->
              <!-- <div class="row">
                <div class="d-none d-lg-block">
                  <div class="col-6 col-lg-4 col-md-4 text-btn mt-3 mb-3">
                    <button class="btn btn-blue-color text-white" @click="practiceDesktop()">
                      About me
                    </button>
                  </div>
                </div>
                <div class="d-block d-lg-none">
                  <div class="col-6 col-lg-4 col-md-4 text-btn mt-3 mb-3">
                    <button class="btn btn-blue-color text-white" @click="practiceMobile()">
                      About me
                    </button>
                  </div>
                </div> -->
              <!-- <div class="col-12 col-lg-12 col-md-12">
                  <h6 class="div-text">
                    Complete this section, and join groups and communities. You
                    also get to view a patient’s case sheets across RxIx doctors
                    and record your patient consultations.
                  </h6>
                </div> -->
              <!-- </div> -->
              <!-- HERE THE NAVIGATION IS DIFFRENT SO I USE THE BUTTON 2 TIMES  -->
              <!-- <div class="row">
                <div class="d-none d-lg-block">
                  <div class="col-6 col-lg-4 col-md-4 text-btn mt-3 mb-3">
                    <button class="btn btn-blue-color text-white" @click="practiceDesktop()">
                      My practice
                    </button>
                  </div>
                </div>
                <div class="d-block d-lg-none">
                  <div class="col-6 col-lg-4 col-md-4 text-btn mt-3 mb-3">
                    <button class="btn btn-blue-color text-white" @click="practiceMobile()">
                      My practice
                    </button>
                  </div>
                </div> -->
              <!-- <div class="col-12 col-lg-12 col-md-12">
                  <h6 class="div-text">
                    To get access to library of resources, enable RxIx users to
                    contact you directly, and to receive home consultation
                    requests, please complete this section.
                  </h6>
                </div> -->
              <!-- </div> -->

              <div class="row mt-3">
                <p class="text-start font-weight-bold">Best Wishes!</p>
                <div class="col-md-6 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>

                <div class="col-md-6 col-6">
                  <p class="text-end">{{ getCommunity.createdAt }}</p>
                </div>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <doctorfooter></doctorfooter>
</template>
<script>
import doctorfooter from "./doctor-footer.vue"
import { defineComponent } from "vue";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  components: {
    doctorfooter
  },
  props: ["role", "approve"],
  data() {
    return {
      clinicHome: false,
      offerConsult: false,
      clinicResource: false,
      postConsult: false,
      getCaseInput: false,
      joinCommunityPatient: false,
      joindoctorData: [],
      getCommunity: {},
      doctorStatus: [],
      button: [],
      patientJoinCommunity: [],
      DoctorId: {},
      doctorSignupPracticeArea: [],
      doctorPatientGroup: [],
      getAllPracticeArea: [],
      patientArray: [],
      // hideGroup: [],
      newPatientGroups: [],
      filteredValue: [],
      patientfilteredValue: [],
      filtercheckValue: [],
      patientfiltercheckValue: [],
      doctorConsultation: [],
      doctorsHistory: [],
      uniqueDoctorHistory: [],
      allUniqueDoctorHistoryData: [],
      allClinicData: [],
      clinicAdmin: {},
      clinicTeamAdmin: {},
      userConsutationRecord: [],
      correspondingFootwearOrder: [],
      AcceptedOrder: [],
      getClinicianDetails: [],
      getFootwearOrder: [],
      userarrayData: [],
      fullUserFamilyConsultationRecord: [],

    };
  },
  methods: {
    formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  ,

    userData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/public-user`)
        .then(async (response) => {
          this.userarrayData = response.data
          // .filter((users) => {
          //   return(
          //     users.id == this.getFootwearOrder[0].patient_name
          //   )
          // })
          // console.log(this.userarrayData,"==>userarrayData")
        })
    },


    async getMedicalRecords() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

      try {
        const consultationResponse = await axios.get(`${BASE_API_URL}/upload-records`);

        if (consultationResponse?.data) {
          this.userConsutationRecord = consultationResponse.data
          // .filter(filterData => filterData?.user_id?.id === this.publicDataSignUp?.id)
          // .map(filterData => {
          //   return {
          //     ...filterData,
          //     newcreatedAt: filterData.createdAt,
          //     createdAt: moment(filterData.createdAt).format("DD/MM/YYYY, h:mm a")
          //   };
          // });


          // this.fullUserFamilyConsultationRecord = this.userConsutationRecord.map(data => ({
          //   id: data.id,
          //   userName: data.family_id || data.user_id,
          //   role: data.family_id?.role || data.user_id?.role,
          //   nextConsultationDate: data.nextConsultationDate,
          //   consultationDate: data.consultation_date,
          //   doctorName: data.doctor_name,
          //   createdAt: data.createdAt,
          //   newcreatedAt: data.newcreatedAt,
          //   updateAt: data.updateAt,
          //   doctorData: data.doctor_id,
          //   clinicianOthers: data.clinician_others_id,
          //   isConsultation: data.isConsultation
          // }));
        }

        const footwearOrderResponse = await axios.get(`${BASE_API_URL}/footwear-order`);

        if (footwearOrderResponse?.data) {

          this.getFootwearOrder = footwearOrderResponse.data.filter((order) => {
            return (
              order.order_status == "Accepted"
            )
          })


          this.correspondingFootwearOrder = this.userarrayData.filter((users) => {
            return (
              users.id == this.getFootwearOrder[0].patient_name
            )
          })


          // this.correspondingFootwearOrder = this.getFootwearOrder.filter(order => {
          //   return (
          //     order.patient_name == this.publicId
          //   )
          // });

        }

        this.AcceptedOrder = this.correspondingFootwearOrder.filter(order => {
          return (
            order.order_status == "Accepted"
          )
          
        });
        
        this.getClinicianDetails = consultationResponse.filter(order => {
          return (
            order.id == this.AcceptedOrder[0].upload_records_data
          )
        });
        

      } catch (error) {
        console.log("Error occurred:", error.response);  // Debugging log
      }
    },



    async allUrl() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let endpoints = [
        `${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`,
        `${BASE_API_URL}/hcp-types?hcpTypes=doctor`,
        `${BASE_API_URL}/hcps`,
        `${BASE_API_URL}/patient-support/add`,
        `${BASE_API_URL}/commentshistory/${this.DoctorId}`,
      ];

      axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then
        ();
    },
    consultationPlace() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Consultation-Place' });
    },
    async getUsersStatus() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
        .then((response) => {

          if (response?.data) {
            if (response.data?.status?.status === "Approved") {
              this.button.push(response.data?.status?.id);
            } else if (response.data?.status?.status === "Rejected") {
              this.$swal("Please fix the review comments of your request for access");
              return;
            } else if (response.data?.status === null) {
              Swal.fire({
                title: "Please complete the About Me section of the Settings Page",
                // icon:'question',
                confirmButtonColor: "#7066e0",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  const query = this.$route.query;
                  window.scrollTo(0, 0);
                  this.$router.push({ path: `/doctor/settings`, query });
                }
              });
            } else if (response.data?.status?.status === "UnderReview") {
              this.$swal("Your request for access is under review. Please wait.");
              return;
            }
          }
        });
    },

    async practiceDesktop() {
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({ path: `/doctor/settings`, query });
    },
    async practiceMobile() {
      const query = this.$route.query;
      window.scrollTo(0, 0);
      this.$router.push({ path: `/doctor/mobile-settings`, query });
    },

    async getCase() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let DoctorUserId = localStorage.getItem("id");
      this.DoctorId = JSON.parse(DoctorUserId);
      await axios
        .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
        .then((response) => {
          this.getCommunity = response.data;


          this.doctorConsultation = this.getCommunity.Doctorconsultaion;
          this.doctorPatientGroup = this.getCommunity.doctorPatientId;

          this.doctorSignupPracticeArea = this.getCommunity.practiceId;
          this.doctorCreationDate = response.data.createdAt;
          response.data.createdAt = moment(response.data.createdAt).format(
            "DD/MM/YYYY, h:mm a"
          );
          response.data.updateAt = moment(response.data.updateAt).format(
            "DD/MM/YYYY, h:mm a"
          );

          this.getCaseInput = true;
        });

      this.addPracticeareaId = localStorage.getItem("practice-area");
      await axios
        .get(
          `${BASE_API_URL}/hcp-types/${this.addPracticeareaId}?hcpTypes=doctor`
        )
        .then(async (response) => {
          if (response.data) {
            this.getAllPracticeArea = response.data;
            // if (this.addPracticeareaId) {
            //   this.openPracticearea = true;
            // }
            this.filteredValue = response.data.filter(
              (item) =>
                item.createdAt > this.doctorCreationDate
            );
            this.filtercheckValue = await this.filteredValue.filter(
              (checkList) => checkList.check == true
            );
            this.patientJoinCommunity = [response.data[0]];
            this.patientDatas = this.patientJoinCommunity[0];
          }

        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    getClinicConsultation() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/clinic-consultation`)
        .then(response => {
          this.allClinicData = response.data
          this.clinicAdmin = this.allClinicData.filter((data) => { return data.id == this.getCommunity.clinic_hcp_id })
          this.clinicTeamAdmin = this.allClinicData.filter((data) => { return data.id == this.getCommunity.clinic_admin_id })



        })
    },
    async getDoctorHistory() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let DoctorUserId = localStorage.getItem("id");
      this.DoctorId = JSON.parse(DoctorUserId);
      this.allUniqueDoctorHistoryData = []
      await axios.get(`${BASE_API_URL}/doctor-history`)
        .then(async (response) => {
          response.data.map((date) => {
            date.createdAt = moment(date.createdAt).format("DD/MM/YYYY, h:mm a")
          })
          this.doctorsHistory = response.data
          const createdData = this.doctorsHistory.filter(item => {
            return item.doctorPatientGroupId?.id === this.DoctorId
          });
          this.uniqueDoctorHistory = createdData
          this.uniqueDoctorHistory.map((item) => {
            let createdDataDetials;
            createdDataDetials = {
              createdAt: item.createdAt,
              patientGroupStatus: item.doctor_status,
              doctorGroupName: item.doctor_patientGroup,
              doctorPracticeArea: item.doctor_practiceArea
            }
            // createdDataDetials.createdAt = moment(createdDataDetials.createdAt).format("DD/MM/YYYY, h:mm a")
            this.allUniqueDoctorHistoryData.push(createdDataDetials)
          })


        })
        .catch(function (error) {
          console.error("Error fetching data:", error);
        })
    },
    async getTopics() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      await axios.get(`${BASE_API_URL}/hcps`).then((response) => {
        if (response?.data) {
          this.Option = response.data?.map((hcpType, index) => {
            hcpType.firstCreateDate = hcpType.createdAt;
            hcpType.createdAt = moment(hcpType.createdAt).format(
              "DD/MM/YYYY, h:mm a"
            );
            hcpType.index = index;
            return hcpType;
          });
        }
      });
      await axios
        .get(`${BASE_API_URL}/commentshistory/${this.DoctorId}`)
        .then((response) => {
          let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);

          response.data.map((date) => {
            date.createdAt = moment(date.createdAt).format("DD/MM/YYYY, h:mm a")
          })
          response.data.map((commentsText) => {
            if (commentsText.users.id === this.DoctorId) {
              this.doctorStatus.push(commentsText)
            }
          })
        });
    },

    async patientsupAdd() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      this.addGroupId = localStorage.getItem("GroupArray");
      await axios.get(`${BASE_API_URL}/hcps`).then((response) => {
        this.doctorCreateDate = response.data[0].createdAt;

        this.getCaseInput = true;
      });
      await axios
        .get(`${BASE_API_URL}/patient-support/add`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.joindoctorData = response.data;
            this.patientfilteredValue = response.data.filter(
              (item) => item.createdAt > this.doctorCreateDate
            );
            this.patientfilteredValue.map((dataID) => {
              dataID.createdAt = moment(dataID.createdAt).format(
                "DD/MM/YYYY, h:mm a"
              );
            });
            this.patientfiltercheckValue = this.patientfilteredValue.filter(
              (checkList) => checkList.status == true
            );
            // if (this.patientfilteredValue.length) {
            // this.patientfilteredValue.map((doctorInfo) => {
            //   this.doctorPatientGroup.map((approvedDoctor) => {
            // if (approvedDoctor.id === doctorInfo.id) {
            // if (!this.hideGroup.includes(doctorInfo.id)) {
            //   this.hideGroup.push(doctorInfo.id);
            // }
            //     }
            //   });
            // });
            // }
            this.joindoctorData.map(async (group, index) => {
              this.localId = localStorage.getItem("id");
              this.idsPatient = group.hcps_id;
              if (this.localId == this.idsPatient?.id) {
                this.patientArray.push(group);
              }
              group.createdAt = moment(group.updateAt).format(
                "DD/MM/YYYY, h:mm a"
              );
              group.updateAt = moment(group.updateAt).format(
                "DD/MM/YYYY, h:mm a"
              );
              group.date = moment(group.date).format("DD/MM/YYYY, h:mm a");
              group.index = index;
              return group;
            });
          }
        });
    },
    joinPatientGroup(patientGroup) {
      this.newPatientGroups.push(patientGroup);
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      this.addGroupId = localStorage.getItem("addGroup");
      this.newPatientGroups = this.newPatientGroups.concat(
        this.doctorPatientGroup
      );
      let payloads = {
        doctorPatientId: this.newPatientGroups,
      };
      axios
        .put(
          `${BASE_API_URL}/hcps/${localStorage.getItem("id")}/update`, payloads
        )
        .then((response) => {
          if (response.data) {
            // this.getCase();
            this.getDoctorHistory();
          }
        });
    },

  },
  created: async function () {
    await this.userData();
    await this.getCase();
    await this.getDoctorHistory();
    this.patientsupAdd();
    this.allUrl();
    this.getTopics();
    await this.getClinicConsultation();
    // this.currentPath = this.$route.path
    switch (this.$route.path.split("/")[3]) {
      case "postconsultation":
        this.postConsult = true;
        break;
      case "offerconsultation":
        this.offerConsult = true;
        break;
      case "accessclinical":
        this.clinicResource = true;
        break;
      case "receiveconsultation":
        this.clinicHome = true;
        break;
      case "getcase":
        this.getCaseInput = true;
        break;
      case "joinCommunityPatientInput":
        this.joinCommunityPatient = true;
        break;
      default:
        break;
    }
    await this.getMedicalRecords();
  },
});
</script>
<style>
.feed-icon-link {
  color: #cccccc !important;
  pointer-events: none;
}

.anchorTag {
  font-weight: bold;
  color: #000;
  cursor: pointer;
  background-color: #b7b7b7;
}

.messageBox {
  padding: 1rem;
  margin: 1rem;
}

/* .wistomBorder {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 15rem;
} */
.cardBorder {
  padding: 15px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 20px;
}

span.textstyle {
  font-size: 20px;
  font-weight: 700px;
}

p.timestyle {
  font-size: 12px;
  color: #545759;
}

button.btn.btn-action {
  background: #00979e;
  color: #fff;
  width: 100%;
  border-radius: inherit;
}

.btn-text {
  text-align: center;
  margin: 15px;
  padding: 20px;
  color: #00979e;
}

.col-opt {
  width: 100%;
  display: grid;
  justify-content: center;
}

.col-fix.mt-3 {
  border: 2px solid #00979e;
  width: 206px;
  /* margin-bottom: 30px; */
}

button.btn.btn-blue-color.text-white {
  width: fit-content;
}

div.btn.btn-action-background1 {
  background: #fff;
  color: #00979e;
  width: 100%;
  border-radius: inherit;
  border: 2px solid #00979e
}

.eyes-text1 p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center
}

.box-text-bg1 {
  font-size: 45px;
  color: #fff;
}

.col-opt-box1 {
  width: 100%;
  display: grid;
  justify-content: center;
}

.col-fix.mt-3.color-bg1 {
  border: 2px solid #00979e;
  width: 235px;
  /* margin-bottom: 30px; */
  background-color: #00979e;
}

.col-box {
  display: flex;
  justify-content: center;
  margin: 40px 40px 0px 40px;
  position: relative;
  bottom: 20px;
}

.btn-text-bg1 {
  text-align: center;
  padding: 15px;
  color: #fff;
  background-color: #00979e;
}

.btn.btn-action-background1 {
  color: #00979e;
}

.box-shadow1 {
  box-shadow: 0px 0px 0px 1px red, 0px 0px 0px 0px red !important;
}

.box-shadow2 {
  box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
}

@media screen and (max-width: 991px) {
  .col-box {
    display: flex;
    margin: auto;
  }

  .messageBox {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 769px) {
  .div-text {
    margin-top: 1rem;
  }
}
</style>
